import $ from "jquery";
import {updateAgentPhoneNumber} from "./fields/agent-phone.js";

const householdSize = $("#householdSize");
const income = $("#income");
const insuranceType = $("#insuranceType");
const lifeEvent = $("#lifeEvent");
const asidesUnder65 = $(".under-65-only");
const asidesOver65 = $(".over-65-only");

function healthForm() {
    /* If insurance type changes, change the phone number */
    // FIXME: Verify we are save these in session storage
    updateAgentPhoneNumber(sessionStorage.getItem("agentPhoneNumberUnder65"), sessionStorage.getItem("agentPhoneNumberUnder65Uri"));
    householdSize.data("val-required", true);
    income.data("val-required", true);
    lifeEvent.data("val-required", true);
}

function medicareForm() {
    /* If insurance type changes, change the phone number */
    updateAgentPhoneNumber(sessionStorage.getItem("agentPhoneNumberOver65"), sessionStorage.getItem("agentPhoneNumberOver65Uri"));
    householdSize.data("val-required", false);
    income.data("val-required", false);
    lifeEvent.data("val-required", false);
}

export function whichForm() {
    const requiredFieldMarkers = $(".form-required");
    requiredFieldMarkers.removeClass("d-none");
    const requiredFields = $("[data-val-required]").filter(function () {
        return $(this).data("val-required");
    });
    requiredFields.data("val-required", "true");
    const insuranceTypeVal = insuranceType.val();
    if (insuranceTypeVal === "Medicare Supplement" || insuranceTypeVal === "Medicare Advantage") {
        if ($("#health-quote-form").length) {
            medicareForm();
            if (insuranceTypeVal === "Medicare Advantage") {
                requiredFieldMarkers.addClass("d-none");
                requiredFields.data("val-required", "false");
            }
            /* Note this bypasses saving customer form info */
            window.location.href = "/thank-you-medicare?insuranceType=Medicare Supplement";
        }
    } else {
        healthForm();
    }
    whichAsides();
}

function whichAsides() {
    if (asidesUnder65.length) {
        const insuranceTypeVal = insuranceType.val();
        if (insuranceTypeVal === "Medicare Supplement" || insuranceTypeVal === "Medicare Advantage") {
            asidesUnder65.addClass("d-none");
            asidesOver65.removeClass("d-none");
        } else {
            asidesUnder65.removeClass("d-none");
            asidesOver65.addClass("d-none");
        }
    }
}

whichForm();
